import { useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import EmptyCategory from "../../components/EmptyCategory";

const SculptureGalery = ({ drawings, categories }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="containerSculpture">
      <div className="sculptureCtn">
        <Header categories={categories} />
        <EmptyCategory />
        <Footer />
      </div>
    </div>
  );
};

export default SculptureGalery;
