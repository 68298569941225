import { useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Grid from "../../components/Grid";

const UnclassifiedGalery = ({ drawings, categories }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="containerUnclassified">
      <div className="unclassifiedCtn">
        <Header categories={categories} />
        <Grid drawings={drawings} />
        <Footer />
      </div>
    </div>
  );
};

export default UnclassifiedGalery;
