import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Contact = ({ categories }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="contactCtn">
      <Header categories={categories} />
      <div className="content">
        <div className="instagram">
          <a
            href="https://www.instagram.com/otto_is_drawing/"
            target="_blank"
            rel="noreferrer"
          >
            <button type="button" className="instagramLogo">
              <FontAwesomeIcon className="fa fa-instagram" icon={faInstagram} />
            </button>
            <p className="pseudo">@otto_is_drawing</p>
          </a>
        </div>
        <div className="contactDescription">
          <div className="titleCtn">
            <h1 className="title">To contact me</h1>
            <h2 className="subTitle">To contact me</h2>
          </div>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium,
            nisi deleniti qui corporis voluptates blanditiis voluptatum impedit
            excepturi sit fugiat quia suscipit repudiandae doloremque doloribus
            ea numquam architecto dolore nostrum. Illum quisquam fuga ea
            architecto vel non adipisci, aut maiores. Ipsa optio veritatis at,
            ipsum atque quos et?
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
