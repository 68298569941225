import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Lightbox from "react-18-image-lightbox";

import Pagination from "../Pagination";

const Grid = ({ drawings }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [images, setImages] = useState(null);
  const [imageClicked, setImageClicked] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [drawingsPerPage] = useState(25);
  const [nPages, setNPages] = useState(0);
  const [currentDrawings, setCurrentDrawings] = useState([]);

  const indexOfLastDrawing = currentPage * drawingsPerPage;
  const indexOfFirstDrawing = indexOfLastDrawing - drawingsPerPage;

  useEffect(() => {
    if (drawings) {
      const images = drawings.map((drawing) => {
        return drawing._embedded["wp:featuredmedia"][0].media_details.sizes
          .medium_large.source_url;
      });

      const currentDrawings = drawings.slice(
        indexOfFirstDrawing,
        indexOfLastDrawing
      );

      setImages(images);
      setNPages(Math.ceil(drawings.length / drawingsPerPage));
      setCurrentDrawings(currentDrawings);
    }
  }, [
    drawings,
    currentPage,
    drawingsPerPage,
    indexOfFirstDrawing,
    indexOfLastDrawing,
  ]);

  return (
    <>
      <div className="gridCtn">
        {drawings && (
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 320: 1, 599: 2, 899: 3 }}
          >
            <Masonry gutter="2" className="galery">
              {currentDrawings.map((drawing) => (
                <li
                  key={drawing.id}
                  className="imageCtnList"
                  onClick={() => {
                    setIsOpen(true);
                    setImageClicked(
                      images.indexOf(
                        drawing._embedded["wp:featuredmedia"][0].media_details
                          .sizes.medium_large.source_url
                      )
                    );
                  }}
                >
                  <img
                    className="picture"
                    src={
                      drawing._embedded["wp:featuredmedia"][0].media_details
                        .sizes.medium_large.source_url
                    }
                    alt="drawing"
                  />
                  <h1
                    className="title"
                    dangerouslySetInnerHTML={{
                      __html: drawing.title.rendered,
                    }}
                  />
                  <span
                    className="description"
                    dangerouslySetInnerHTML={{
                      __html: drawing.excerpt.rendered,
                    }}
                  />
                  <FontAwesomeIcon className="fa-regular fa-eye" icon={faEye} />
                </li>
              ))}
            </Masonry>
          </ResponsiveMasonry>
        )}
        {nPages > 0 && (
          <Pagination
            nPages={nPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={images[imageClicked]}
          nextSrc={images[(imageClicked + 1) % images.length]}
          prevSrc={images[(imageClicked + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setImageClicked((imageClicked + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setImageClicked((imageClicked + 1) % images.length)
          }
        />
      )}
    </>
  );
};

export default Grid;
