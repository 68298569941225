import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  let year = new Date();

  return (
    <div className="footerCtn">
      <div className="links">
        <Link className="gtcu" to="/gtcu">
          GTCU
        </Link>
      </div>
      <div className="copyCtn">
        <p className="copyright">&copy; Otto is drawing {year.getFullYear()}</p>
      </div>
    </div>
  );
};

export default Footer;
