import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const nextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  const firstPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - currentPage + 1);
  };
  const lastPage = () => {
    if (currentPage !== nPages)
      setCurrentPage(currentPage - currentPage + nPages);
  };

  return (
    <div className="paginationCtn">
      <ul className="paginationElm">
        <li>
          <Link to="#" onClick={firstPage}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <FontAwesomeIcon icon={faChevronLeft} />
          </Link>
        </li>
        <li>
          <Link to="#" onClick={prevPage}>
            <FontAwesomeIcon className="chevronLeft" icon={faChevronLeft} />
          </Link>
        </li>
        {pageNumbers.map((pgNumber) => (
          <li key={pgNumber}>
            <NavLink
              to="#"
              className={({ isActive }) =>
                currentPage === pgNumber && isActive ? "active" : ""
              }
              onClick={() => setCurrentPage(pgNumber)}
            >
              {pgNumber}
            </NavLink>
          </li>
        ))}
        <li>
          <Link to="#" onClick={nextPage}>
            <FontAwesomeIcon className="chevronRight" icon={faChevronRight} />
          </Link>
        </li>
        <li>
          <Link to="#" onClick={lastPage}>
            <FontAwesomeIcon icon={faChevronRight} />
            <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
