import React from "react";
import { useNavigate } from "react-router-dom";
import Typewriter from "typewriter-effect";

import Video from "../../components/Video";

const Welcome = () => {
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate("/home");
  };

  return (
    <div className="welcomeCtn">
      <div className="videoCtn">
        <Video />
      </div>

      <div className="content">
        <div className="typewriterCtn">
          <Typewriter
            onInit={(typewriter) => {
              typewriter
                .changeDelay(100)
                .typeString(
                  'Hi <img style="height:45px;width:45px;margin-bottom:-1rem" className="emoji" src="https://img.icons8.com/emoji/60/000000/waving-hand-emoji.png"/> <br/>'
                )
                .pauseFor(500)
                .typeString("My name is Otto <br/>")
                .pauseFor(500)
                .typeString("And as you will see, I draw!")
                .start();
            }}
          />
        </div>

        <div className="btnCtn">
          <button
            className="btn-welcome"
            type="submit"
            onClick={(event) => handleSubmit(event)}
          >
            Welcome to my world
          </button>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
