import video from "../../videos/video.mp4";

const Video = () => {
  return (
    <>
      <video className="video" loop autoPlay muted playsInline>
        <source src={video} type="video/mp4" />
      </video>
    </>
  );
};

export default Video;
