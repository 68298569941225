import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import ThemeContextWrapper from "./utils/theme/ThemeWrapper";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <ThemeContextWrapper>
      <App />
    </ThemeContextWrapper>
  </Router>
);
