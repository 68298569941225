import { useEffect } from "react";
import Header from "../../components/Header";
import Grid from "../../components/Grid";
import Footer from "../../components/Footer";

const Home = ({ drawings, categories }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="containerHome">
      <div className="homeCtn">
        <Header categories={categories} />
        <Grid drawings={drawings} />
        <Footer />
      </div>
    </div>
  );
};

export default Home;
