import Sun from "../../images/sun.svg";
import Moon from "../../images/moon.svg";

const ToggleThemeButton = ({ toggleDark }) => {
  return (
    <>
      <input
        type="checkbox"
        id="checkbox-toggle"
        onChange={() => {
          toggleDark();
        }}
        {...{
          checked: localStorage.getItem("theme") === "dark",
        }}
      />
      <label htmlFor="checkbox-toggle">
        <img src={Sun} className="sun" alt="sun" />
        <img src={Moon} className="moon" alt="moon" />
      </label>
    </>
  );
};

export default ToggleThemeButton;
