import { useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const About = ({ categories }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="aboutCtn">
      <Header categories={categories} />
      <div className="content">
        <div className="firstSection">
          <div className="illustration1">
            <div className="palette">
              <div className="pencil position"></div>
              <div className="brush position"></div>
              <div className="redTube1 position"></div>
              <div className="redTube2 position"></div>
              <div className="greenTube position"></div>
              <div className="blueTube position"></div>
              <div className="orangeTube position"></div>
              <div className="purpleTube position"></div>
            </div>
          </div>
          <div className="firstDescription">
            <div className="titleCtn">
              <h1 className="title">About me</h1>
              <h2 className="subTitle">About me</h2>
            </div>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Laudantium, nisi deleniti qui corporis voluptates blanditiis
              voluptatum impedit excepturi sit fugiat quia suscipit repudiandae
              doloremque doloribus ea numquam architecto dolore nostrum. Illum
              quisquam fuga ea architecto vel non adipisci, aut maiores. Ipsa
              optio veritatis at, ipsum atque quos et?
            </p>
          </div>
        </div>
        <div className="secondSection">
          <div className="secondDescription">
            <div className="titleCtn">
              <h1 className="title">Expertise</h1>
              <h2 className="subTitle">Expertise</h2>
            </div>
            <p>
              Ratione nisi ipsum quam magni ullam, soluta in illo id obcaecati
              dicta? At, natus, voluptatem iure explicabo, id consectetur
              reiciendis neque odit possimus quia quod distinctio magni
              voluptas. Quasi suscipit quaerat eum. Perspiciatis in debitis
              magnam sint maxime tenetur laboriosam rerum eum?
            </p>
          </div>
          <div className="illustration2">
            <div className="easel position"></div>
            <div className="rainbow position"></div>
            <div className="stain1 position"></div>
            <div className="stain2 position"></div>
            <div className="stain3 position"></div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
