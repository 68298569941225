import React, { useState, useEffect } from "react";
import { ThemeContext, themes } from "../../context/ThemeContext";

export default function ThemeContextWrapper(props) {
  const [theme, setTheme] = useState(themes.light);

  function changeTheme(theme) {
    setTheme(theme);
  }

  useEffect(() => {
    if (localStorage.getItem("theme") === "dark") {
      setTheme(themes.dark);
    } else {
      setTheme(themes.light);
    }
  }, []);

  useEffect(() => {
    switch (theme) {
      case themes.dark:
        document.body.classList.add("dark");
        break;
      case themes.light:
      default:
        document.body.classList.remove("dark");
        break;
    }
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, changeTheme }}>
      {props.children}
    </ThemeContext.Provider>
  );
}
