import { useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Gtcu = ({ categories }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="gtcuCtn">
      <Header categories={categories} />
      <div className="content">
        <div className="titleCtn">
          <h1 className="title">General terms and conditions of use</h1>
          <h2 className="subTitle">General terms and conditions of use</h2>
          <h3 className="title2">Intellectual property</h3>
        </div>
        <p className="gtcuText">
          You are informed that everything you see or read on this website is
          protected by intellectual property law. Access to our site grants you
          the right to private and non-exclusive use of this site. <br />
          <br />
          The artist Otto is the owner of the intellectual property rights or
          holds the rights of use on all the elements accessible on the site, in
          particular the texts, images, graphics, logo, sounds and software. Any
          reproduction, representation, modification, publication, adaptation of
          all or part of the elements of the site, whatever the means or the
          process used, is forbidden, except with the prior written
          authorization of : Otto is drawing. <br />
          <br /> Any unauthorized exploitation of the site or of any of the
          elements it contains will be considered as constituting an
          infringement and will be prosecuted in accordance with the provisions
          of articles L.111-1 and following of the Intellectual Property Code.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default Gtcu;
