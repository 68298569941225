import { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

import ToggleThemeButton from "../ToggleThemeButton";
import { ThemeContext, themes } from "../../context/ThemeContext";

const Header = ({ categories }) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [lightMode, setLightMode] = useState(true);

  return (
    <div className="headerCtn">
      <div className="navigation navigationFit">
        <div className="logo">
          <Link className="logoLink" to="/home">
            <div className="logoImg"></div>
          </Link>
        </div>

        <nav className="nav">
          <ul
            className={menuVisible ? "linksMobile linksMobileLarge" : "links"}
          >
            <div className="toggleThemeCtn">
              <ThemeContext.Consumer>
                {({ changeTheme }) => (
                  <ToggleThemeButton
                    toggleDark={() => {
                      setLightMode(!lightMode);
                      changeTheme(lightMode ? themes.dark : themes.light);
                      localStorage.setItem(
                        "theme",
                        lightMode ? "dark" : "light"
                      );
                    }}
                  />
                )}
              </ThemeContext.Consumer>
            </div>
            <li className="link">
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "linkItem")}
                to="/home"
              >
                Home
              </NavLink>
            </li>
            {categories &&
              categories.map((category) => (
                <li className="link" key={category}>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "active" : "linkItem"
                    }
                    to={`/${category}`.toLowerCase()}
                  >
                    {category}
                  </NavLink>
                </li>
              ))}
            <li className="link">
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "linkItem")}
                to="/about"
              >
                About me
              </NavLink>
            </li>
            <li className="link">
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "linkItem")}
                to="/contact"
              >
                Contact
              </NavLink>
            </li>
          </ul>
          <button
            className="burgerMenuCtn"
            onClick={() => {
              setMenuVisible(!menuVisible);
            }}
          >
            <div className="burgerMenu"></div>
          </button>
          <div className="instagram">
            <a
              className="instagramLink"
              href="https://www.instagram.com/otto_is_drawing/"
              target="_blank"
              rel="noreferrer"
            >
              <button type="button" className="instagramLogo">
                <FontAwesomeIcon
                  className="fa fa-instagram"
                  icon={faInstagram}
                />
              </button>
            </a>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
