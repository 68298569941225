/* eslint-disable no-unused-vars */
/* eslint-disable no-eval */
import { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import axios from "axios";
import dotenv from "dotenv";

import Welcome from "./pages/welcome";
import Home from "./pages/home";
import About from "./pages/about";
import Contact from "./pages/contact";
import MangaGalery from "./pages/manga";
import PaintingGalery from "./pages/painting";
import SketchGalery from "./pages/sketch";
import SculptureGalery from "./pages/sculpture";
import UnclassifiedGalery from "./pages/unclassified";
import Gtcu from "./pages/gtcu";
import ScrollToTopButton from "./components/ScrollToTopButton";
import Loader from "./components/Loader";

import "../src/styles/index.scss";

dotenv.config();

function App() {
  const [drawings, setDrawings] = useState(null);
  const [Manga, setManga] = useState([]);
  const [Painting, setPainting] = useState([]);
  const [Sketch, setSketch] = useState([]);
  const [Sculpture, setSculpture] = useState([]);
  const [Unclassified, setUnclassified] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchDrawings = async () => {
      setLoading(true);
      await axios
        .get(`${process.env.REACT_APP_API_URI}/drawing?_embed&per_page=100`)
        .then((res) => {
          const drawingsList = res.data;

          setDrawings(drawingsList);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    };

    fetchDrawings();
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      await axios
        .get(`${process.env.REACT_APP_API_URI}/drawing_category`)
        .then((res) => {
          const categoriesData = res.data;

          const categoriesName = categoriesData.map(
            (category) => category.name
          );
          setCategories(categoriesName);

          const categoriesObjects = categoriesName.map((category) => {
            return {
              name: category,
              setter: eval("set" + category),
            };
          });

          if (drawings) {
            const handleDrawingsByCategory = (drawings, categoriesObjects) => {
              categoriesObjects.map((category) => {
                const categoryList = drawings.filter((drawing) => {
                  return drawing._embedded["wp:term"][0]
                    .map((taxonomy) => taxonomy.name)
                    .includes(category.name);
                });
                return category.setter(categoryList);
              });
            };

            handleDrawingsByCategory(drawings, categoriesObjects);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };

    fetchCategories();
  }, [drawings]);

  const { pathname } = useLocation();

  return (
    <div className="App">
      {loading && pathname !== "/" && <Loader />}
      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route
          path="/home"
          element={<Home drawings={drawings} categories={categories} />}
        />
        <Route
          path="/manga"
          element={<MangaGalery drawings={Manga} categories={categories} />}
        />
        <Route
          path="/painting"
          element={
            <PaintingGalery drawings={Painting} categories={categories} />
          }
        />
        <Route
          path="/sketch"
          element={<SketchGalery drawings={Sketch} categories={categories} />}
        />
        <Route
          path="/sculpture"
          element={
            <SculptureGalery drawings={Sculpture} categories={categories} />
          }
        />
        <Route
          path="/unclassified"
          element={
            <UnclassifiedGalery
              drawings={Unclassified}
              categories={categories}
            />
          }
        />
        <Route path="/about" element={<About categories={categories} />} />
        <Route path="/contact" element={<Contact categories={categories} />} />
        <Route path="/gtcu" element={<Gtcu categories={categories} />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <ScrollToTopButton />
    </div>
  );
}

export default App;
